<template>
    <div class="row" style="padding: 15px 0.5rem;margin:0px">
        <div class="col-sm-10">
            <div class="card">
                <div class="card-body">
                    <FullCalendar :options="calendarOptions" />
                </div>
            </div>
        </div>
        <div class="col-sm-2">
            <button class="btn btn-block btn-secondary" style="min-height:40px;border-left:10px #EC008C solid" v-on:click="openModal">Agenda Baru</button>
            <div style="width:100%;background:#FDFF9D;min-height:100px;margin-top:12px;padding:16px;color:#7D7D7D">
                <p>
                    <b>Tips</b><br>
                    Klik dua kali di kalender untuk membuat agenda baru atau melaliui button di atas.<br>
                    Klik di kalender untuk melihat detail, mengubah, atau menghapus data
                </p>
            </div>
        </div>
        <div class="modal fade" id="modal-default">
            <div class="modal-dialog">
                <div class="modal-content">
                    <form role="form" @submit="submitForm" ref="form">
                        <div class="modal-header">
                            <h4 class="modal-title">Agenda</h4>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <p v-if="errors.length" class="alert alert-danger alert-dismissible fade show" role="alert">
                                <b>Please correct the following error(s):</b>
                                <ul>
                                  <li v-for="error in errors" v-bind:key="error">{{ error }}</li>
                                </ul>
                                <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                              </button>
                            </p>
                            <div class="col-md-6 form-group">
                                <label class="control-label">Range waktu</label>
                                <input type="hidden" name="row_id" value="" v-model="form.id"/>
                                <input type="hidden" name="created_by" value="" v-model="form.created_by"/>
                                <input type="text" class="form-control" id="daterange" autocomplete="off">
                            </div>
                            <div class="row" style="padding: 7.5px;">
                                <div class="col-md-6 form-group">
                                    <label class="control-label">Mulai</label>
                                    <timepicker  v-model="form.jam_mulai" />
                                </div>
                                <div class="col-md-6 form-group">
                                    <label class="control-label">Selesai</label>
                                    <timepicker  v-model="form.jam_selesai" />
                                </div>
                            </div>
                            <div class="col-md-12 form-group">
                                <label class="control-label">Agenda Kegiatan</label>
                                <input id="agenda" class="form-control agenda" v-model="form.agenda" type="text" required="required" name="agenda" />
                            </div>
                            <div class="col-md-5 form-group">
                                <label class="control-label">Kategory</label>
                                <input id="kategory" class="form-control kategory" v-model="form.kategory" type="text" required="required" name="kategory" />
                            </div>
                            <div class="col-md-4 form-group">
                                <label class="control-label">Person in charge</label>
                                <v-select :options="optMarketing" v-model="form.person" :reduce="opt => opt.id" />
                            </div>
                            <div class="col-md-12 form-group">
                                <label class="control-label">Catatan</label>
                                <textarea class="form-control" v-model="form.catatan"></textarea>
                            </div>
                        </div>
                        <div class="modal-footer justify-content-between">
                            <button type="button" class="btn btn-default" data-dismiss="modal">Close</button>
                            <button v-if="form.id > 0" type="button" class="btn btn-danger" style="margin-left: auto;" v-on:click="deleteItem">Delete</button>
                            <button type="submit" class="btn btn-primary">Save changes</button>
                        </div>
                    </form>
                </div>
                <!-- /.modal-content -->
            </div>
            <!-- /.modal-dialog -->
        </div>
    </div>
</template>
<script type="text/javascript">
import { auth, authFetch } from '@/libs/hxcore';
import FullCalendar from '@fullcalendar/vue'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'
import $ from 'jquery';
import Daterangepicker from 'daterangepicker';
import "daterangepicker/daterangepicker.css";
import vSelect from 'vue-select';
import "vue-select/dist/vue-select.css";
import moment from 'moment';
import timepicker from '@/components/Timepicker';

export default {
    name: 'KalenderMarketing',
    components: {
        FullCalendar,
        vSelect,
        timepicker,
    },
    data() {
        return {
            errors: [],
            optMarketing: [],
            form: {
                date:'',
                start: '',
                end: '',
                agenda: '',
                kategory: '',
                person: '',
                catatan: '',
                jam_mulai:'',
                jam_selesai:'',
                created_by: ''
            },
            calendarOptions: {
                plugins: [dayGridPlugin, interactionPlugin],
                initialView: 'dayGridMonth',
                dateClick: this.handleDateClick,
                eventClick: this.eventClick,
                events: [],
                displayEventTime: false,
            }
        };
    },
    created: function() {
        this.roles = this.$route.meta.roles;
        if (auth.user()) {
            this.form.created_by = auth.user().id;
        }
    },
    mounted() {
        var self = this;
        new Daterangepicker(this.$refs.daterange, {
        }, function(d1, d2) {
            self.form.start = d1.format('DD/MM/YYYY')
            self.form.end = d2.format('DD/MM/YYYY')
        });

        authFetch('/pr/kalender_marketing')
            .then(res => {
                if (res.status === 201) {

                } else if (res.status === 400) {}
                return res.json();
            })
            .then(js => {
                this.calendarOptions.events = js.data;
            })
        authFetch('/pr/kalender_marketing/marketing')
            .then(res => {
                if (res.status === 201) {

                } else if (res.status === 400) {}
                return res.json();
            })
            .then(js => {
                this.optMarketing = js.data;
            })
    },
    methods: {
        eventClick: function(arg) {
            var self = this;
            authFetch('/pr/kalender_marketing/'+arg.event.id)
                .then(res => {
                    if (res.status === 201) {

                    } else if (res.status === 400) {}
                    return res.json();
                })
                .then(js => {
                    var s = js.tanggal_mulai.split('-');
                    var e = js.tanggal_selesai.split('-');
                    this.form.start = s[2]+'/'+s[1]+'/'+s[0];
                    this.form.end = e[2]+'/'+e[1]+'/'+e[0];
                    this.form.agenda = js.title;
                    this.form.kategory = js.label_text;
                    this.form.person = js.staff_id_pic;
                    this.form.catatan = js.catatan;
                    this.form.id = js.id;
                    this.form.jam_mulai = js.jam_mulai ? js.jam_mulai.substring(0,5) : ''
                    this.form.jam_selesai = js.jam_selesai? js.jam_selesai.substring(0,5) : ''
                    this.form.created_by = js.created_by
                    $("#modal-default").modal()

                    $('#daterange').daterangepicker({ startDate: moment(js.tanggal_mulai), endDate: moment(js.tanggal_selesai) });
                        $('#daterange').on('apply.daterangepicker', function(ev, picker) {
                            self.form.start = picker.startDate.format('DD/MM/YYYY')
                            self.form.end = picker.endDate.format('DD/MM/YYYY')
                        });
                })
        },
        handleDateClick: function(arg) {
            var self = this;
            this.$refs.form.reset();
            this.form.id = '';
            this.form.start = '';
            this.form.end = '';
            this.form.agenda = '';
            this.form.kategory = '';
            this.form.person = '';
            this.form.catatan = '';
            this.form.jam_mulai = '';
            this.form.jam_selesai = '';

            $('input[name="row_id"]').val('')
            
            var date = ('0' + (arg.date.getMonth()+1)).slice(-2)+'/'+('0' + arg.date.getDate()).slice(-2)+'/'+arg.date.getFullYear();
            $('#daterange').daterangepicker({ startDate: date, endDate: date });
            this.form.start = ('0' + arg.date.getDate()).slice(-2)+'/'+('0' + (arg.date.getMonth()+1)).slice(-2)+'/'+arg.date.getFullYear()
            this.form.end = ('0' + arg.date.getDate()).slice(-2)+'/'+('0' + (arg.date.getMonth()+1)).slice(-2)+'/'+arg.date.getFullYear()
            $("#modal-default").modal()

            $('#daterange').on('apply.daterangepicker', function(ev, picker) {
                self.form.start = picker.startDate.format('DD/MM/YYYY')
                self.form.end = picker.endDate.format('DD/MM/YYYY')
            });
        },
        openModal: function() {
            var self = this;
            this.$refs.form.reset();
            this.form.id = '';
            this.form.start = '';
            this.form.end = '';
            this.form.agenda = '';
            this.form.kategory = '';
            this.form.person = '';
            this.form.catatan = '';
            this.form.jam_mulai = '';
            this.form.jam_selesai = '';

            $('input[name="row_id"]').val('')
            $("#modal-default").modal()
            $('#daterange').daterangepicker({ startDate: moment(), endDate: moment() });

            $('#daterange').on('apply.daterangepicker', function(ev, picker) {
                self.form.start = picker.startDate.format('DD/MM/YYYY')
                self.form.end = picker.endDate.format('DD/MM/YYYY')
            });
        },
        submitForm: function(ev) {
            const e = this.$refs;
            var data = Object.keys(this.form).map(key => encodeURIComponent(key) + '=' + encodeURIComponent(this.form[key])).join('&')
            var urlSubmit = '/pr/kalender_marketing';
            if (this.form.id) {
                this.method = 'PUT';
            } else {
                this.method = 'POST';
            }
            if (this.method == 'PUT') urlSubmit = '/pr/kalender_marketing/' + this.form.id;

            authFetch(urlSubmit, {
                    method: this.method,
                    body: data
                })
                .then(res => {
                    if (res.status === 201) {

                    } else if (res.status === 400) {}
                    return res.json();
                })
                .then(js => {

                    this.errors = [];
                    if (!js.success) {
                        for (var key in js) {
                            if (js.hasOwnProperty(key)) {
                                this.errors.push(js[key])
                            }
                        }

                        return;
                    }
                    // this.table.api().ajax.reload();
                    // $(e.formDialog).modal('hide');
                    $("#modal-default").modal('hide')
                    authFetch('/pr/kalender_marketing')
                    .then(res => {
                        if (res.status === 201) {

                        } else if (res.status === 400) {}
                        return res.json();
                    })
                    .then(js => {
                        this.calendarOptions.events = js.data;
                    })
                });

            ev.preventDefault();
        },
        deleteItem: function() {
            authFetch('/pr/kalender_marketing/' + this.form.id, {
                    method: 'DELETE'
                })
                .then(res => {
                    if (res.status === 201) {

                    } else if (res.status === 400) {}
                    return res.json();
                })
                .then(js => {

                    this.errors = [];
                    if (!js.success) {
                        for (var key in js.details) {
                            if (js.details.hasOwnProperty(key)) {
                                this.errors.push(js.details[key])
                            }
                        }

                        return;
                    }
                    // this.table.api().ajax.reload();
                    // $(e.formDialog).modal('hide');
                    $("#modal-default").modal('hide')
                    authFetch('/pr/kalender_marketing')
                    .then(res => {
                        if (res.status === 201) {

                        } else if (res.status === 400) {}
                        return res.json();
                    })
                    .then(js => {
                        this.calendarOptions.events = js.data;
                    })
                });
        }
    }
}
</script>